<template>
  <b-row class="m-0 p-0">
    <b-col class="col-12 col-md p-0">
      <search-by-category
        :key="update_search_by"
        :keyword_dad="keywords"
        :where_dad="where"
        :categories="data.categories"
        :no_categories="data.no_categories ? data.no_categories : false"
        :disabled_custom_category="!!data.disabled_custom_category"
        @add_keyword_filter="addKeywordFilter"
        ref="search_by_category"
      />
    </b-col>
    <button-submit-filters
      class="mb-1 mb-md-0"
      :searched="data.searched" 
      :loading="data.loading" 
      :text="getText()" 
      :total_results="data.total_results" 
      @sendFilters="sendFilters()"
      @closeSideBar="closeSideBar()" 
      :key="`change_submit_${data.loading}`"
    />
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import { getFormat } from '@/libs/utils/formats';

export default {
  name: 'searchByActions',
  components: {
    BRow,
    BCol,
    searchByCategory: () => import('@/views/buscador/filters/components/searchByCategory.vue'),
    buttonSubmitFilters: () => import('@/views/buscador/buttonSubmitFilters.vue')
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      update_search_by: false,
      keywords: '',
      where: 'anywhere',
    }
  },
  methods: {
    closeSideBar() {
      this.$emit('closeSideBar')
    },
    sendFilters() {
      this.$emit('sendFilters');
    },
    addKeywordFilter(new_models, keywords, where) {
      this.$emit('add_keyword_filter', new_models, keywords, where)
    },
    getText() {
      if (this.data.loading) return this.$t("search.searching")
      if (this.data.total_results > 0 && this.data.searched) return `${getFormat(this.data.total_results)} ${this.$t("search.results")}`
      if (!this.data.searched) return `${getFormat(this.data.total_results)} ${this.$t("search.showResults")}`
      return this.$t('search.noResults')
    },
  }
}
</script>